import { TextNormalization } from '@/app/core/utils/text-normalization'
import {
    FrontGlobalParametersDetailResponse,
    IdentifierRegexResponse,
    IdentifierTypeResponse,
    LaboratoryConfigurationResponse,
    UserappPatchRequest,
    UserInformationDetailResponse
} from '@api/services/common/initialization/common-initialization.dto'
import { axios, ProductType } from '@vitro/anapath-frontend-library-api'
import { orderBy } from 'lodash'

class CommonInitializationService {
    // Returns the list of all active identifier regular expressions
    public async getIdentifierRegexes(): Promise<IdentifierRegexResponse[]> {
        const response = await axios.get<IdentifierRegexResponse[]>('common/identifierRegexes')
        return response.data
    }

    // returns the global parameters of the application
    public async getGlobalParameters(): Promise<FrontGlobalParametersDetailResponse> {
        const response = await axios.get<FrontGlobalParametersDetailResponse>('globalParameters')
        return response.data
    }

    // returns the product type configured
    async getProductType(): Promise<ProductType> {
        const response = await axios.get<ProductType>('/common/productType')
        return response.data
    }

    // Returns the list of entities by identifier
    public async getIdentifierTypes(): Promise<IdentifierTypeResponse[]> {
        const response = await axios.get<IdentifierTypeResponse[]>('identifierTypes')
        return response.data
    }

    // Return the configuration for each laboratory, including operations, modules and sections for the current user
    public async getLaboratoriesConfiguration(): Promise<LaboratoryConfigurationResponse[]> {
        const response = await axios.get<LaboratoryConfigurationResponse[]>('laboratoriesConfiguration')
        return orderBy(response.data, configuration => TextNormalization.removeDiacritics(configuration.laboratory.labName), ['asc'])
    }

    // returns the logged in user information
    public async getUserInformation(): Promise<UserInformationDetailResponse> {
        const response = await axios.get<UserInformationDetailResponse>('userInformation')
        return response.data
    }

    // patches logged in user
    public async patchUserInformation(userappPatchRequest: UserappPatchRequest): Promise<UserInformationDetailResponse> {
        const response = await axios.patch<UserInformationDetailResponse>('userInformation', userappPatchRequest)
        return response.data
    }
}
export const commonInitializationService = new CommonInitializationService()
