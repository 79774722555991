import App from '@/app/app.vue'
import { router } from '@/app/router'
import { IonicVue } from '@ionic/vue'
import { createApp } from 'vue'
import { setupApp } from '@/app/setup-app'
/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css'
/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'
/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/display.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
/* Theme variables */
import '@/theme/global.scss'
/* Ionic icons */
import { addIcons } from 'ionicons'
import * as icons from 'ionicons/icons'
addIcons(icons)
/* PWA */
import '@/registerServiceWorker'
import { defineCustomElements } from '@ionic/pwa-elements/loader'

export const app =
    createApp(App)
        .use(IonicVue)
        .use(router)
app.config.performance = process.env.NODE_ENV !== 'production'

setupApp.register(app)

setupApp.loadProductType().then(() => {
    router.isReady().then(async () => {
        app.mount('#app')
        await defineCustomElements(window)
    })
})
