<template>
  <svg
    x="0px"
    y="0px"
    viewBox="0 0 292.5 95"
    style="enable-background:new 0 0 292.5 95;"
    xml:space="preserve"
  >
    <g>
      <path
        class="st0"
        d="M194.6,71.68h10.52l-0.38,3.07h-3.59l-1.94,14.17H196l1.94-14.17h-3.74L194.6,71.68z"
      />
      <path
        class="st0"
        d="M205.3,76.17h2.79l-0.22,1.73c0.84-1.38,1.84-2.06,3.01-2.06c0.28,0,0.57,0.07,0.88,0.22l-1.14,2.85
        c-0.24-0.08-0.41-0.13-0.52-0.13c-0.61,0-1.16,0.35-1.63,1.06s-0.86,2.13-1.13,4.28l-0.62,4.79h-3.14L205.3,76.17z"
      />
      <path
        class="st0"
        d="M223.07,76.17h3.13l-1.78,12.75h-3.1l0.19-1.37c-0.68,0.63-1.32,1.07-1.91,1.32s-1.25,0.38-1.99,0.38
        c-1.69,0-3.03-0.62-4.03-1.86c-1-1.24-1.5-2.69-1.5-4.34c0-1.26,0.32-2.46,0.96-3.59s1.5-2.02,2.57-2.66
        c1.08-0.64,2.2-0.96,3.38-0.96c0.79,0,1.51,0.18,2.18,0.54c0.67,0.36,1.22,0.89,1.65,1.58L223.07,76.17z M218.37,86.46
        c0.77,0,1.44-0.17,2.02-0.51c0.57-0.34,1.06-0.88,1.44-1.61c0.39-0.73,0.58-1.48,0.58-2.25c0-1.01-0.29-1.82-0.88-2.44
        s-1.36-0.92-2.32-0.92c-1.12,0-2.06,0.41-2.84,1.23c-0.78,0.82-1.17,1.88-1.17,3.18c0,1,0.29,1.8,0.88,2.41
        S217.44,86.46,218.37,86.46z"
      />
      <path
        class="st0"
        d="M241.19,78.8l-2.65,1.44c-0.75-0.95-1.76-1.43-3.02-1.43c-0.85,0-1.61,0.18-2.26,0.54
        c-0.66,0.36-1.18,0.89-1.57,1.57c-0.39,0.69-0.59,1.41-0.59,2.18c0,0.98,0.31,1.77,0.93,2.36c0.62,0.59,1.46,0.89,2.54,0.89
        c1.49,0,2.63-0.5,3.43-1.51l2.46,1.74c-0.7,0.83-1.56,1.48-2.6,1.95s-2.19,0.71-3.46,0.71c-1.99,0-3.55-0.55-4.7-1.64
        s-1.72-2.54-1.72-4.34c0-2.05,0.71-3.81,2.12-5.26s3.24-2.17,5.46-2.17C238.08,75.84,239.95,76.83,241.19,78.8z"
      />
      <path
        class="st0"
        d="M245.59,71.25h3.08l-1.28,9.32l4.99-4.4h4.29l-6.45,5.68l5.64,7.07h-3.75l-5.01-6.25l-0.86,6.25h-3.07
        L245.59,71.25z"
      />
      <path
        class="st0"
        d="M258.41,76.17h3.1l-1.72,12.75h-3.12L258.41,76.17z M260.56,70.95c0.53,0,0.99,0.19,1.37,0.57
        s0.57,0.83,0.57,1.37s-0.19,0.99-0.57,1.37s-0.83,0.57-1.37,0.57s-0.99-0.19-1.37-0.57s-0.57-0.83-0.57-1.37s0.19-0.99,0.57-1.37
        S260.03,70.95,260.56,70.95z"
      />
      <path
        class="st0"
        d="M264.59,76.17h3.12l-0.18,1.29c1.2-1.08,2.44-1.62,3.73-1.62c1.1,0,2.01,0.35,2.71,1.05
        c0.7,0.7,1.05,1.58,1.05,2.65c0,0.5-0.07,1.25-0.2,2.24l-0.98,7.14h-3.12l0.95-6.97c0.09-0.72,0.14-1.26,0.14-1.62
        c0-0.52-0.15-0.91-0.44-1.19s-0.71-0.42-1.25-0.42c-0.53,0-1.05,0.18-1.58,0.54c-0.53,0.36-0.93,0.87-1.21,1.51
        c-0.28,0.64-0.54,1.85-0.78,3.63l-0.6,4.51h-3.12L264.59,76.17z"
      />
      <path
        class="st0"
        d="M288.51,76.17h3.14l-1.46,10.24c-0.28,2.01-0.56,3.34-0.86,3.99c-0.46,1.01-1.23,1.85-2.32,2.52
        c-1.09,0.67-2.45,1-4.09,1c-1.37,0-2.55-0.26-3.57-0.78s-1.77-1.19-2.27-2.01l2.74-1.41c0.82,0.85,1.89,1.28,3.21,1.28
        c0.77,0,1.43-0.14,1.99-0.41c0.56-0.27,0.99-0.64,1.3-1.11s0.53-1.15,0.66-2.07c-1.16,1.01-2.47,1.51-3.94,1.51
        c-1.02,0-1.96-0.25-2.83-0.76s-1.54-1.21-2.04-2.13c-0.5-0.91-0.74-1.93-0.74-3.05c0-1.26,0.32-2.44,0.97-3.54
        c0.65-1.11,1.53-1.98,2.64-2.63c1.12-0.65,2.28-0.97,3.49-0.97c0.73,0,1.4,0.14,2,0.43c0.6,0.29,1.19,0.77,1.76,1.44L288.51,76.17z
        M284.66,78.79c-1.17,0-2.15,0.4-2.95,1.2s-1.2,1.78-1.2,2.94c0,0.95,0.3,1.7,0.89,2.27s1.39,0.86,2.39,0.86
        c0.75,0,1.44-0.17,2.08-0.52c0.63-0.35,1.14-0.85,1.51-1.5s0.56-1.35,0.56-2.11c0-0.9-0.31-1.65-0.92-2.24
        S285.62,78.79,284.66,78.79z"
      />
    </g>
    <g>
      <g>
        <path
          class="st0"
          d="M216.8,39.04c0-10.53-4.63-19.25-19.43-19.25c-8.08,0-14.16,2.27-17.25,3.99l2.54,8.9
          c2.91-1.82,7.72-3.36,12.26-3.36c6.81,0,8.08,3.36,8.08,5.81v0.54c-15.71,0-26.06,5.45-26.06,16.98c0,7.08,5.36,13.62,14.35,13.62
          c5.27,0,9.81-1.91,12.71-5.45h0.27l0.82,4.45h12.44c-0.55-2.45-0.73-6.54-0.73-10.71V39.04z M203.45,49.12
          c0,0.82-0.09,1.64-0.27,2.36c-0.91,2.81-3.81,5.08-7.17,5.08c-3.09,0-5.45-1.72-5.45-5.26c0-5.27,5.63-6.99,12.89-6.99V49.12z"
        />
        <path
          class="st0"
          d="M237.05,8.9l-13.53,3.72v8.26h-5.9v10.17h5.9v18.16c0,6.26,1.27,10.53,3.81,13.17
          c2.27,2.27,5.99,3.81,10.44,3.81c3.81,0,7.17-0.54,8.9-1.18l-0.09-10.44c-1.27,0.27-2.18,0.36-4.08,0.36
          c-4.09,0-5.45-2.45-5.45-7.81V31.05h9.9V20.88h-9.9V8.9z"
        />
        <path
          class="st0"
          d="M276.09,19.88c-2.54,0-5.08,0.63-7.17,1.72c-2.18,1.09-3.99,2.63-5.45,4.63h-0.18V0.82h-13.8v64.47h13.8
        V38.95c0-1,0.09-2.18,0.45-3c1-2.54,3.27-4.9,6.9-4.9c5.17,0,7.17,3.99,7.17,9.81v24.42h13.8V39.41
        C291.61,25.97,284.9,19.88,276.09,19.88z"
        />
        <path
          class="st0"
          d="M40.41,39.05c0-10.53-4.63-19.25-19.43-19.25c-8.08,0-14.16,2.27-17.25,4l2.54,8.9
        c2.91-1.82,7.72-3.36,12.26-3.36c6.81,0,8.08,3.36,8.08,5.81v0.54c-15.71,0-26.06,5.45-26.06,16.98c0,7.08,5.36,13.62,14.35,13.62
        c5.27,0,9.81-1.91,12.71-5.45h0.27l0.82,4.45h12.44c-0.55-2.45-0.73-6.54-0.73-10.71V39.05z M27.07,49.12
        c0,0.82-0.09,1.63-0.27,2.36c-0.91,2.81-3.81,5.08-7.17,5.08c-3.09,0-5.45-1.72-5.45-5.27c0-5.27,5.63-6.99,12.89-6.99V49.12z"
        />
        <path
          class="st0"
          d="M70.83,19.89c-7.45,0-11.89,4.27-13.71,7.17h-0.27l-0.64-6.17H44.23c0.18,3.99,0.36,8.63,0.36,14.16v30.24
        h13.8v-25.6c0-1.27,0.09-2.54,0.45-3.45c1-2.54,3.27-5.18,7.08-5.18c4.99,0,6.99,3.9,6.99,9.62v24.61h13.8V39.05
        C86.72,25.97,79.91,19.89,70.83,19.89z"
        />
        <path
          class="st0"
          d="M156.42,20.13c-6.45,0-11.53,2.54-14.8,7.17h-0.18l-0.64-6.17h-4.82c-0.44-2.87-2.91-5.09-5.9-5.09
        c-3.31,0-5.99,2.69-5.99,5.99c0,1.68,0.7,3.2,1.83,4.29c-0.03,0-0.05-0.01-0.08-0.01c-2.95-3.84-7.91-6.28-15.72-6.28
        c-8.08,0-14.16,2.27-17.25,4l2.54,8.9c2.91-1.82,7.72-3.36,12.26-3.36c6.81,0,8.08,3.36,8.08,5.81v0.54
        c-15.71,0-26.06,5.45-26.06,16.98c0,5.55,3.32,10.71,9.06,12.71c-0.21,0.56-0.35,1.17-0.35,1.81c0,2.83,2.3,5.14,5.14,5.14
        s5.14-2.3,5.14-5.14c0-0.49-0.09-0.96-0.22-1.41c3.36-0.77,6.24-2.44,8.29-4.94h0.27l0.82,4.45h11.35v17.98h5.34
        c0.71,3.52,3.83,6.18,7.55,6.18c4.25,0,7.71-3.46,7.71-7.71c0-3.94-2.98-7.16-6.8-7.62V61.44h0.18c2,2.91,6.17,5.09,11.53,5.09
        c9.9,0,20.16-7.63,20.16-23.79C174.85,28.57,166.14,20.13,156.42,20.13z M125.8,22.03c0-2.36,1.92-4.28,4.28-4.28
        s4.28,1.92,4.28,4.28s-1.92,4.28-4.28,4.28S125.8,24.4,125.8,22.03z M103.54,70.85c-1.89,0-3.43-1.54-3.43-3.43
        s1.54-3.43,3.43-3.43s3.43,1.54,3.43,3.43S105.42,70.85,103.54,70.85z M148.07,81.98c0,3.31-2.69,5.99-5.99,5.99
        s-5.99-2.69-5.99-5.99s2.69-5.99,5.99-5.99S148.07,78.68,148.07,81.98z M153.38,52.94c-4.01,0-7.44-2.45-8.88-5.93l-5.35,1.74
        c0.14,0.62,0.22,1.26,0.22,1.92c0,3.41-1.94,6.34-4.76,7.84l6.24,15.9c-0.58,0.09-1.14,0.24-1.66,0.45l-6.16-15.7
        c-0.81,0.24-1.66,0.42-2.55,0.42c-2.86,0-5.38-1.37-7.01-3.47l-15.28,9.14c-0.25-0.52-0.57-1-0.97-1.41l15.34-9.17
        c-0.55-1.09-0.88-2.3-0.94-3.59l-5.37-0.28c-0.44,3.19-3.15,5.66-6.46,5.66c-3.62,0-6.55-2.93-6.55-6.55
        c0-3.62,2.93-6.55,6.55-6.55c3.33,0,6.06,2.5,6.47,5.72l5.46,0.28c0.56-3.77,3.46-6.76,7.19-7.43c-0.03-3.51-0.05-6.33-0.07-8.91
        l0.31-5.08c0.31,0.05,0.63,0.1,0.96,0.1c0.32,0,0.63-0.05,0.93-0.09l0.07,13.89c3.38,0.24,6.23,2.33,7.53,5.29l5.37-1.74
        c-0.14-0.66-0.22-1.35-0.22-2.05c0-5.31,4.31-9.62,9.62-9.62c5.31,0,9.62,4.31,9.62,9.62C163,48.64,158.69,52.94,153.38,52.94z"
        />
      </g>
    </g>
  </svg>
</template>
