import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_anapath_premium_logo = _resolveComponent("anapath-premium-logo")!
  const _component_anapath_standard_logo = _resolveComponent("anapath-standard-logo")!
  const _component_anapath_tracking_logo = _resolveComponent("anapath-tracking-logo")!

  return (_ctx.visiblePremiumLogo)
    ? (_openBlock(), _createBlock(_component_anapath_premium_logo, {
        key: 0,
        alt: "AnaPath Premium Logo",
        "aria-hidden": "true"
      }))
    : (_ctx.visibleStandardLogo)
      ? (_openBlock(), _createBlock(_component_anapath_standard_logo, {
          key: 1,
          alt: "AnaPath Standard Logo",
          "aria-hidden": "true"
        }))
      : (_ctx.visibleTrackingLogo)
        ? (_openBlock(), _createBlock(_component_anapath_tracking_logo, {
            key: 2,
            alt: "AnaPath Tracking Logo",
            "aria-hidden": "true"
          }))
        : _createCommentVNode("", true)
}