<template>
  <svg
    x="0px"
    y="0px"
    viewBox="0 0 292.5 95"
    style="enable-background:new 0 0 292.5 95;"
    xml:space="preserve"
  >
    <g>
      <path
        class="st0"
        d="M216.3,40.31c0-10.48-4.61-19.16-19.35-19.16c-8.05,0-14.1,2.26-17.18,3.98l2.53,8.86
        c2.89-1.81,7.68-3.34,12.2-3.34c6.78,0,8.05,3.34,8.05,5.79v0.54c-15.64,0-25.94,5.42-25.94,16.9c0,7.05,5.33,13.56,14.28,13.56
        c5.24,0,9.76-1.9,12.66-5.42h0.27l0.81,4.43h12.39c-0.54-2.44-0.72-6.51-0.72-10.67V40.31z M203.01,50.34
        c0,0.81-0.09,1.63-0.27,2.35c-0.9,2.8-3.8,5.06-7.14,5.06c-3.07,0-5.43-1.72-5.43-5.24c0-5.24,5.61-6.96,12.84-6.96V50.34z"
      />
      <path
        class="st0"
        d="M236.46,10.3L222.99,14v8.23h-5.88v10.12h5.88v18.08c0,6.24,1.26,10.49,3.8,13.11c2.26,2.26,5.97,3.8,10.4,3.8
        c3.8,0,7.14-0.54,8.86-1.18l-0.09-10.4c-1.26,0.27-2.17,0.36-4.07,0.36c-4.07,0-5.43-2.44-5.43-7.77v-16h9.85V22.23h-9.85V10.3z"
      />
      <path
        class="st0"
        d="M275.33,21.24c-2.53,0-5.06,0.63-7.14,1.72c-2.17,1.09-3.98,2.62-5.42,4.61h-0.18V2.25h-13.74v64.18h13.74
        V40.22c0-1,0.09-2.17,0.45-2.98c1-2.53,3.26-4.88,6.87-4.88c5.15,0,7.14,3.98,7.14,9.76v24.32h13.74V40.67
        C290.79,27.29,284.1,21.24,275.33,21.24z"
      />
      <path
        class="st0"
        d="M40.69,40.31c0-10.48-4.61-19.16-19.35-19.16c-8.05,0-14.1,2.26-17.18,3.98l2.53,8.86
        c2.89-1.81,7.68-3.34,12.21-3.34c6.78,0,8.04,3.34,8.04,5.79v0.54C11.31,36.97,1,42.39,1,53.87c0,7.05,5.33,13.56,14.28,13.56
        c5.24,0,9.76-1.9,12.66-5.42h0.27l0.81,4.43h12.38c-0.54-2.44-0.72-6.51-0.72-10.67V40.31z M27.4,50.35c0,0.81-0.09,1.63-0.27,2.35
        c-0.9,2.8-3.8,5.06-7.14,5.06c-3.07,0-5.42-1.72-5.42-5.24c0-5.24,5.6-6.96,12.84-6.96V50.35z"
      />
      <path
        class="st0"
        d="M70.97,21.24c-7.41,0-11.84,4.25-13.65,7.14h-0.27l-0.63-6.15H44.48c0.18,3.98,0.36,8.59,0.36,14.1v30.1h13.74
        V40.95c0-1.27,0.09-2.53,0.45-3.44c1-2.53,3.26-5.15,7.05-5.15c4.97,0,6.96,3.89,6.96,9.58v24.5h13.74V40.31
        C86.79,27.3,80.01,21.24,70.97,21.24z"
      />
      <path
        class="st0"
        d="M156.18,21.48c-6.42,0-11.48,2.53-14.73,7.14h-0.18l-0.63-6.15h-4.8c-0.44-2.86-2.9-5.07-5.88-5.07
        c-3.29,0-5.97,2.68-5.97,5.97c0,1.68,0.7,3.19,1.82,4.27c-0.03,0-0.05-0.01-0.08-0.01c-2.94-3.83-7.88-6.25-15.65-6.25
        c-8.05,0-14.1,2.26-17.18,3.98l2.53,8.86c2.89-1.81,7.68-3.35,12.2-3.35c6.78,0,8.05,3.35,8.05,5.79v0.54
        c-15.64,0-25.95,5.42-25.95,16.9c0,5.52,3.3,10.66,9.02,12.65c-0.21,0.56-0.34,1.16-0.34,1.8c0,2.82,2.29,5.12,5.12,5.12
        c2.82,0,5.12-2.29,5.12-5.12c0-0.49-0.09-0.95-0.22-1.4c3.35-0.77,6.21-2.43,8.25-4.91h0.27l0.81,4.43h11.3v17.9h5.31
        c0.71,3.51,3.81,6.16,7.52,6.16c4.23,0,7.67-3.44,7.67-7.67c0-3.92-2.97-7.13-6.77-7.58V62.61h0.18c1.99,2.89,6.15,5.06,11.48,5.06
        c9.85,0,20.07-7.59,20.07-23.69C174.54,29.89,165.86,21.48,156.18,21.48z M125.7,23.38c0-2.35,1.91-4.26,4.26-4.26
        c2.35,0,4.26,1.91,4.26,4.26s-1.91,4.26-4.26,4.26C127.61,27.64,125.7,25.73,125.7,23.38z M103.53,71.98
        c-1.88,0-3.41-1.53-3.41-3.41s1.53-3.41,3.41-3.41s3.41,1.53,3.41,3.41S105.41,71.98,103.53,71.98z M147.87,83.06
        c0,3.29-2.68,5.97-5.97,5.97c-3.29,0-5.97-2.68-5.97-5.97s2.68-5.97,5.97-5.97C145.19,77.09,147.87,79.77,147.87,83.06z
         M153.16,54.15c-3.99,0-7.41-2.44-8.85-5.91l-5.33,1.73c0.14,0.62,0.22,1.25,0.22,1.91c0,3.4-1.94,6.32-4.74,7.8l6.21,15.83
        c-0.57,0.09-1.13,0.24-1.65,0.45l-6.13-15.63c-0.81,0.24-1.65,0.41-2.54,0.41c-2.85,0-5.35-1.37-6.98-3.45l-15.22,9.1
        c-0.25-0.52-0.57-1-0.97-1.41l15.28-9.13c-0.55-1.08-0.88-2.29-0.94-3.57L116.17,52c-0.43,3.18-3.13,5.64-6.43,5.64
        c-3.6,0-6.52-2.92-6.52-6.52c0-3.6,2.92-6.52,6.52-6.52c3.32,0,6.03,2.49,6.44,5.7l5.43,0.28c0.56-3.76,3.44-6.73,7.16-7.4
        c-0.03-3.5-0.05-6.3-0.07-8.87l0.31-5.06c0.31,0.05,0.63,0.1,0.95,0.1c0.32,0,0.62-0.05,0.93-0.09l0.07,13.83
        c3.36,0.24,6.21,2.32,7.49,5.27l5.35-1.74c-0.14-0.66-0.22-1.34-0.22-2.04c0-5.29,4.29-9.58,9.58-9.58s9.58,4.29,9.58,9.58
        C162.73,49.86,158.45,54.15,153.16,54.15z"
      />
    </g>
    <g>
      <path
        class="st0"
        d="M199.92,75.19l-2.35,2.08c-0.96-1.24-1.86-1.87-2.7-1.87c-0.41,0-0.75,0.12-1.03,0.37s-0.41,0.54-0.41,0.88
        c0,0.32,0.12,0.67,0.37,1.06s1,1.25,2.26,2.58c0.67,0.71,1.11,1.21,1.32,1.51c0.51,0.68,0.87,1.32,1.09,1.92s0.33,1.2,0.33,1.81
        c0,1.38-0.49,2.53-1.47,3.46c-0.98,0.93-2.24,1.4-3.77,1.4c-2.33,0-4.24-1.11-5.71-3.33l2.47-2c1,1.52,2.07,2.28,3.23,2.28
        c0.55,0,1.02-0.18,1.39-0.54c0.38-0.36,0.57-0.79,0.57-1.29c0-0.4-0.11-0.8-0.34-1.21c-0.23-0.41-0.88-1.19-1.97-2.33
        c-1.14-1.21-1.89-2.12-2.25-2.73c-0.48-0.83-0.72-1.65-0.72-2.45c0-1.06,0.35-2.01,1.04-2.85c0.87-1.05,2.05-1.58,3.52-1.58
        C196.85,72.37,198.55,73.31,199.92,75.19z"
      />
      <path
        class="st0"
        d="M203.37,72.56h3.1l-0.64,4.71h1.97l-0.37,2.68h-1.96l-1.36,10.01h-3.11l1.37-10.01h-1.53l0.36-2.68h1.53
        L203.37,72.56z"
      />
      <path
        class="st0"
        d="M219.23,77.27h3.12l-1.77,12.69h-3.08l0.19-1.36c-0.68,0.63-1.31,1.07-1.9,1.32
        c-0.59,0.25-1.25,0.37-1.98,0.37c-1.68,0-3.02-0.62-4.01-1.86c-0.99-1.24-1.49-2.68-1.49-4.32c0-1.25,0.32-2.44,0.95-3.58
        s1.49-2.01,2.56-2.65c1.07-0.63,2.19-0.95,3.36-0.95c0.78,0,1.51,0.18,2.17,0.54s1.21,0.88,1.64,1.57L219.23,77.27z M214.55,87.52
        c0.77,0,1.44-0.17,2.01-0.51s1.05-0.88,1.44-1.61c0.39-0.73,0.58-1.47,0.58-2.24c0-1-0.29-1.81-0.88-2.43
        c-0.58-0.61-1.35-0.92-2.31-0.92c-1.11,0-2.05,0.41-2.83,1.22c-0.77,0.82-1.16,1.87-1.16,3.16c0,1,0.29,1.8,0.88,2.4
        S213.63,87.52,214.55,87.52z"
      />
      <path
        class="st0"
        d="M225.69,77.27h3.11l-0.18,1.29c1.19-1.08,2.43-1.61,3.71-1.61c1.1,0,2,0.35,2.7,1.04
        c0.7,0.7,1.05,1.58,1.05,2.63c0,0.5-0.07,1.24-0.2,2.23l-0.97,7.11h-3.11l0.95-6.94c0.09-0.72,0.14-1.25,0.14-1.61
        c0-0.51-0.15-0.91-0.44-1.18c-0.29-0.28-0.71-0.41-1.25-0.41c-0.52,0-1.05,0.18-1.57,0.54c-0.52,0.36-0.92,0.86-1.21,1.5
        c-0.28,0.64-0.54,1.85-0.77,3.61l-0.6,4.49h-3.11L225.69,77.27z"
      />
      <path
        class="st0"
        d="M250.02,72.37h3.12l-2.44,17.59h-3.08l0.19-1.36c-0.68,0.63-1.31,1.07-1.9,1.32
        c-0.59,0.25-1.25,0.37-1.98,0.37c-1.68,0-3.02-0.62-4.01-1.86c-0.99-1.24-1.49-2.68-1.49-4.32c0-1.25,0.32-2.44,0.95-3.58
        s1.49-2.01,2.56-2.65c1.07-0.63,2.19-0.95,3.36-0.95c0.78,0,1.51,0.18,2.17,0.54s1.21,0.88,1.64,1.57L250.02,72.37z M244.66,87.52
        c0.77,0,1.43-0.17,2-0.51c0.57-0.34,1.05-0.88,1.44-1.61c0.39-0.73,0.58-1.48,0.58-2.24c0-1-0.29-1.81-0.87-2.43
        c-0.58-0.61-1.35-0.92-2.3-0.92c-1.11,0-2.05,0.41-2.83,1.22c-0.77,0.82-1.16,1.87-1.16,3.16c0,1,0.29,1.8,0.88,2.4
        S243.74,87.52,244.66,87.52z"
      />
      <path
        class="st0"
        d="M265.12,77.27h3.12l-1.77,12.69h-3.08l0.19-1.36c-0.68,0.63-1.31,1.07-1.9,1.32
        c-0.59,0.25-1.25,0.37-1.98,0.37c-1.68,0-3.02-0.62-4.01-1.86c-0.99-1.24-1.49-2.68-1.49-4.32c0-1.25,0.32-2.44,0.95-3.58
        s1.49-2.01,2.56-2.65c1.07-0.63,2.19-0.95,3.36-0.95c0.78,0,1.51,0.18,2.17,0.54s1.21,0.88,1.64,1.57L265.12,77.27z M260.44,87.52
        c0.77,0,1.44-0.17,2.01-0.51s1.05-0.88,1.44-1.61c0.39-0.73,0.58-1.47,0.58-2.24c0-1-0.29-1.81-0.88-2.43
        c-0.58-0.61-1.35-0.92-2.31-0.92c-1.11,0-2.05,0.41-2.83,1.22c-0.77,0.82-1.16,1.87-1.16,3.16c0,1,0.29,1.8,0.88,2.4
        S259.52,87.52,260.44,87.52z"
      />
      <path
        class="st0"
        d="M270.85,77.27h2.78L273.41,79c0.83-1.37,1.83-2.05,3-2.05c0.28,0,0.57,0.07,0.88,0.22L276.14,80
        c-0.24-0.08-0.41-0.13-0.52-0.13c-0.61,0-1.15,0.35-1.63,1.06s-0.85,2.12-1.13,4.26l-0.62,4.77h-3.13L270.85,77.27z"
      />
      <path
        class="st0"
        d="M289.21,72.37h3.12l-2.44,17.59h-3.08l0.19-1.36c-0.68,0.63-1.31,1.07-1.9,1.32
        c-0.59,0.25-1.25,0.37-1.98,0.37c-1.68,0-3.02-0.62-4.01-1.86c-0.99-1.24-1.49-2.68-1.49-4.32c0-1.25,0.32-2.44,0.95-3.58
        s1.49-2.01,2.56-2.65c1.07-0.63,2.19-0.95,3.36-0.95c0.78,0,1.51,0.18,2.17,0.54s1.21,0.88,1.64,1.57L289.21,72.37z M283.86,87.52
        c0.77,0,1.43-0.17,2-0.51c0.57-0.34,1.05-0.88,1.44-1.61c0.39-0.73,0.58-1.48,0.58-2.24c0-1-0.29-1.81-0.87-2.43
        c-0.58-0.61-1.35-0.92-2.3-0.92c-1.11,0-2.05,0.41-2.83,1.22c-0.77,0.82-1.16,1.87-1.16,3.16c0,1,0.29,1.8,0.88,2.4
        S282.93,87.52,283.86,87.52z"
      />
    </g>
  </svg>
</template>
