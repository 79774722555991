import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7357490c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ion-no-margin" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_anapath_type_logo = _resolveComponent("anapath-type-logo")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, { class: "help-module" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_list, {
                    mode: "md",
                    lines: "full"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_list_header, { class: "help-module__header" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_row, { class: "ion-padding-vertical" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_col, { size: "12" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_anapath_type_logo, { class: "anapath-logo" })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_col, null, {
                                default: _withCtx(() => [
                                  _createElementVNode("h6", _hoisted_1, _toDisplayString(_ctx.$t('anapathapp.label.version')) + " " + _toDisplayString(_ctx.version), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      false
                        ? (_openBlock(), _createBlock(_component_ion_item, {
                            key: 0,
                            button: ""
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_button, { fill: "clear" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('anapathapp.action.userManual')), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      _createVNode(_component_ion_item, {
                        button: "",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showWizard()))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_button, { fill: "clear" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('anapathapp.action.introductionGuide')), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_item, {
                        button: "",
                        href: "https://www.anapath.bio/",
                        target: "_blank"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_button, { fill: "clear" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('anapathapp.action.aboutAnapath')), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_item, {
                        button: "",
                        href: "https://www.vitro.bio/",
                        target: "_blank"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_button, { fill: "clear" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('anapathapp.action.aboutVitro')), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      false
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            _createVNode(_component_ion_item, { button: "" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_button, { fill: "clear" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('anapathapp.action.technicalSupport')), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_item, { button: "" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_button, { fill: "clear" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('anapathapp.action.contact')), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ], 64))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}