import { VuePlugin } from '@/app'
import { App } from 'vue'
import { store } from '@api/lib'
import { fontAwesomePlugin } from '@/app/plugins/font-awesome/font-awesome-plugin'
import { ionicPlugin } from '@/app/plugins/ionic/ionic-plugin'
import { axiosPlugin } from '@/app/plugins/axios-library/axios-library-plugin'
import { vueI18nPlugin } from '@/app/plugins/vue-i18n/vue-i18n-plugin'
import { vuexPlugin } from '@/app/plugins/vuex/vuex-plugin'
import { PromiseUtil } from '@/app/core/utils/promise-util'
import { commonInitializationService } from '@api/services/common/initialization/common-initialization.service'

class SetupApp implements VuePlugin {
    public register(app: App<Element>): void {
        fontAwesomePlugin.register(app)
        ionicPlugin.register(app)
        axiosPlugin.register(app)
        vueI18nPlugin.register(app)
        vuexPlugin.register(app)
    }

    public async loadProductType(): Promise<void> {
        const response = await PromiseUtil.toCallSecure(commonInitializationService.getProductType())
        await store.dispatch('changeProductType', response)
    }
}
export const setupApp = new SetupApp()
