<template>
  <svg
    x="0px"
    y="0px"
    viewBox="0 0 292.5 95"
    style="enable-background:new 0 0 292.5 95;"
    xml:space="preserve"
  >
    <g>
      <path
        class="st0"
        d="M216.92,39.04c0-10.53-4.63-19.25-19.43-19.25c-8.08,0-14.16,2.27-17.25,3.99l2.54,8.9
        c2.91-1.82,7.72-3.36,12.26-3.36c6.81,0,8.08,3.36,8.08,5.81v0.54c-15.71,0-26.06,5.45-26.06,16.98c0,7.08,5.36,13.62,14.35,13.62
        c5.27,0,9.81-1.91,12.71-5.45h0.27l0.82,4.45h12.44c-0.55-2.45-0.73-6.54-0.73-10.71V39.04z M203.57,49.12
        c0,0.82-0.09,1.64-0.27,2.36c-0.91,2.81-3.81,5.08-7.17,5.08c-3.09,0-5.45-1.72-5.45-5.26c0-5.27,5.63-6.99,12.89-6.99V49.12z"
      />
      <path
        class="st0"
        d="M237.16,8.9l-13.53,3.72v8.26h-5.9v10.17h5.9v18.16c0,6.26,1.27,10.53,3.81,13.17
        c2.27,2.27,5.99,3.81,10.44,3.81c3.81,0,7.17-0.54,8.9-1.18l-0.09-10.44c-1.27,0.27-2.18,0.36-4.08,0.36
        c-4.09,0-5.45-2.45-5.45-7.81V31.05h9.9V20.88h-9.9V8.9z"
      />
      <path
        class="st0"
        d="M276.2,19.88c-2.54,0-5.08,0.63-7.17,1.72c-2.18,1.09-3.99,2.63-5.45,4.63h-0.18V0.82h-13.8v64.47h13.8V38.95
        c0-1,0.09-2.18,0.45-3c1-2.54,3.27-4.9,6.9-4.9c5.17,0,7.17,3.99,7.17,9.81v24.42h13.8V39.41
        C291.73,25.97,285.01,19.88,276.2,19.88z"
      />
      <path
        class="st0"
        d="M40.53,39.05c0-10.53-4.63-19.25-19.43-19.25c-8.08,0-14.16,2.27-17.25,4l2.54,8.9
        c2.91-1.82,7.72-3.36,12.26-3.36c6.81,0,8.08,3.36,8.08,5.81v0.54c-15.71,0-26.06,5.45-26.06,16.98c0,7.08,5.36,13.62,14.35,13.62
        c5.27,0,9.81-1.91,12.71-5.45H28l0.82,4.45h12.44c-0.55-2.45-0.73-6.54-0.73-10.71V39.05z M27.18,49.12c0,0.82-0.09,1.63-0.27,2.36
        c-0.91,2.81-3.81,5.08-7.17,5.08c-3.09,0-5.45-1.72-5.45-5.27c0-5.27,5.63-6.99,12.89-6.99V49.12z"
      />
      <path
        class="st0"
        d="M70.95,19.89c-7.45,0-11.89,4.27-13.71,7.17h-0.27l-0.64-6.17H44.35c0.18,3.99,0.36,8.63,0.36,14.16v30.24
        h13.8v-25.6c0-1.27,0.09-2.54,0.45-3.45c1-2.54,3.27-5.18,7.08-5.18c4.99,0,6.99,3.9,6.99,9.62v24.61h13.8V39.05
        C86.84,25.97,80.03,19.89,70.95,19.89z"
      />
      <path
        class="st0"
        d="M156.54,20.13c-6.45,0-11.53,2.54-14.8,7.17h-0.18l-0.64-6.17h-4.82c-0.44-2.87-2.91-5.09-5.9-5.09
        c-3.31,0-5.99,2.69-5.99,5.99c0,1.68,0.7,3.2,1.83,4.29c-0.03,0-0.05-0.01-0.08-0.01c-2.95-3.84-7.91-6.28-15.72-6.28
        c-8.08,0-14.16,2.27-17.25,4l2.54,8.9c2.91-1.82,7.72-3.36,12.26-3.36c6.81,0,8.08,3.36,8.08,5.81v0.54
        c-15.71,0-26.06,5.45-26.06,16.98c0,5.55,3.32,10.71,9.06,12.71c-0.21,0.56-0.35,1.17-0.35,1.81c0,2.83,2.3,5.14,5.14,5.14
        c2.83,0,5.14-2.3,5.14-5.14c0-0.49-0.09-0.96-0.22-1.41c3.36-0.77,6.24-2.44,8.29-4.94h0.27l0.82,4.45h11.35v17.98h5.34
        c0.71,3.52,3.83,6.18,7.55,6.18c4.25,0,7.71-3.46,7.71-7.71c0-3.94-2.98-7.16-6.8-7.62V61.44h0.18c2,2.91,6.17,5.09,11.53,5.09
        c9.9,0,20.16-7.63,20.16-23.79C174.97,28.57,166.25,20.13,156.54,20.13z M125.92,22.03c0-2.36,1.92-4.28,4.28-4.28
        c2.36,0,4.28,1.92,4.28,4.28s-1.92,4.28-4.28,4.28C127.84,26.32,125.92,24.4,125.92,22.03z M103.65,70.85
        c-1.89,0-3.43-1.54-3.43-3.43s1.54-3.43,3.43-3.43c1.89,0,3.43,1.54,3.43,3.43S105.54,70.85,103.65,70.85z M148.19,81.98
        c0,3.31-2.69,5.99-5.99,5.99c-3.31,0-5.99-2.69-5.99-5.99s2.69-5.99,5.99-5.99C145.5,75.99,148.19,78.68,148.19,81.98z
         M153.5,52.94c-4.01,0-7.44-2.45-8.88-5.93l-5.35,1.74c0.14,0.62,0.22,1.26,0.22,1.92c0,3.41-1.94,6.34-4.76,7.84l6.24,15.9
        c-0.58,0.09-1.14,0.24-1.66,0.45l-6.16-15.7c-0.81,0.24-1.66,0.42-2.55,0.42c-2.86,0-5.38-1.37-7.01-3.47l-15.28,9.14
        c-0.25-0.52-0.57-1-0.97-1.41l15.34-9.17c-0.55-1.09-0.88-2.3-0.94-3.59l-5.37-0.28c-0.44,3.19-3.15,5.66-6.46,5.66
        c-3.62,0-6.55-2.93-6.55-6.55c0-3.62,2.93-6.55,6.55-6.55c3.33,0,6.06,2.5,6.47,5.72l5.46,0.28c0.56-3.77,3.46-6.76,7.19-7.43
        c-0.03-3.51-0.05-6.33-0.07-8.91l0.31-5.08c0.31,0.05,0.63,0.1,0.96,0.1c0.32,0,0.63-0.05,0.93-0.09l0.07,13.89
        c3.38,0.24,6.23,2.33,7.53,5.29l5.37-1.74c-0.14-0.66-0.22-1.35-0.22-2.05c0-5.31,4.31-9.62,9.62-9.62c5.31,0,9.62,4.31,9.62,9.62
        C163.12,48.64,158.81,52.94,153.5,52.94z"
      />
    </g>
    <g>
      <path
        class="st0"
        d="M195.11,71.68h3.03c1.84,0,3.13,0.16,3.89,0.48c0.93,0.4,1.64,0.95,2.12,1.66c0.48,0.71,0.72,1.54,0.72,2.5
        c0,1.05-0.28,1.99-0.85,2.84c-0.56,0.85-1.32,1.46-2.27,1.85c-0.95,0.39-2.53,0.6-4.75,0.63l-1,7.28h-3.25L195.11,71.68z
         M197.39,78.59c1.76-0.01,2.9-0.19,3.44-0.55c0.54-0.36,0.8-0.89,0.8-1.61c0-0.4-0.1-0.73-0.31-1.01s-0.52-0.46-0.93-0.57
        s-1.22-0.16-2.44-0.16L197.39,78.59z"
      />
      <path
        class="st0"
        d="M206.91,76.17h2.79l-0.22,1.73c0.84-1.38,1.84-2.06,3.01-2.06c0.28,0,0.57,0.07,0.88,0.22l-1.14,2.85
        c-0.24-0.08-0.41-0.13-0.52-0.13c-0.61,0-1.15,0.35-1.63,1.06c-0.48,0.71-0.86,2.13-1.13,4.28l-0.62,4.79h-3.14L206.91,76.17z"
      />
      <path
        class="st0"
        d="M223.12,85.19l2.75,1.43c-0.62,0.81-1.44,1.45-2.46,1.92s-2.15,0.71-3.39,0.71c-1.95,0-3.48-0.55-4.6-1.64
        s-1.68-2.55-1.68-4.36c0-2.07,0.7-3.82,2.1-5.26s3.1-2.15,5.08-2.15c1.2,0,2.26,0.26,3.19,0.79s1.67,1.29,2.21,2.29
        s0.81,2.09,0.81,3.26c0,0.37-0.04,0.8-0.12,1.29h-10.17c0.03,0.89,0.32,1.59,0.87,2.12c0.55,0.53,1.31,0.79,2.28,0.79
        C221.18,86.36,222.22,85.97,223.12,85.19z M223.92,81.16c-0.23-0.78-0.62-1.37-1.17-1.78s-1.21-0.6-1.98-0.6
        c-0.77,0-1.46,0.21-2.07,0.62s-1.09,1-1.41,1.76H223.92z"
      />
      <path
        class="st0"
        d="M230.68,76.17h3.13l-0.19,1.39c1.13-1.15,2.4-1.72,3.81-1.72c0.57,0,1.1,0.1,1.6,0.29
        c0.5,0.19,0.9,0.44,1.21,0.75s0.57,0.73,0.79,1.27c0.59-0.75,1.27-1.33,2.06-1.72c0.79-0.39,1.61-0.59,2.46-0.59
        c1.07,0,1.96,0.36,2.66,1.07c0.7,0.72,1.05,1.65,1.05,2.79c0,0.43-0.04,0.97-0.12,1.61l-1.05,7.61h-3.12l0.9-6.55
        c0.12-0.88,0.18-1.47,0.18-1.76c0-0.6-0.15-1.06-0.45-1.37c-0.3-0.31-0.73-0.47-1.28-0.47c-0.53,0-1.07,0.19-1.62,0.57
        s-0.96,0.88-1.25,1.51c-0.29,0.63-0.53,1.71-0.72,3.24l-0.66,4.83h-3.12l0.95-6.94c0.08-0.56,0.12-1.05,0.12-1.45
        c0-0.56-0.16-1-0.48-1.32c-0.32-0.33-0.75-0.49-1.27-0.49c-0.5,0-1.02,0.17-1.55,0.52c-0.53,0.35-0.94,0.82-1.24,1.42
        s-0.52,1.52-0.69,2.75l-0.72,5.5h-3.12L230.68,76.17z"
      />
      <path
        class="st0"
        d="M252.82,76.17h3.1l-1.72,12.75h-3.12L252.82,76.17z M254.96,70.95c0.53,0,0.99,0.19,1.37,0.57
        s0.57,0.83,0.57,1.37s-0.19,0.99-0.57,1.37s-0.83,0.57-1.37,0.57s-0.99-0.19-1.37-0.57s-0.57-0.83-0.57-1.37s0.19-0.99,0.57-1.37
        S254.43,70.95,254.96,70.95z"
      />
      <path
        class="st0"
        d="M259.03,76.17h3.1l-0.89,6.52c-0.09,0.72-0.14,1.26-0.14,1.62c0,0.56,0.2,1.04,0.59,1.42
        c0.39,0.38,0.9,0.57,1.52,0.57c0.81,0,1.43-0.25,1.86-0.74c0.43-0.49,0.75-1.49,0.96-3l0.87-6.39h3.1l-0.91,6.86
        c-0.21,1.55-0.57,2.74-1.06,3.59c-0.5,0.85-1.16,1.5-1.98,1.95s-1.79,0.68-2.9,0.68c-1.57,0-2.81-0.45-3.74-1.35
        c-0.92-0.9-1.39-2.07-1.39-3.5c0-0.88,0.1-2,0.31-3.38L259.03,76.17z"
      />
      <path
        class="st0"
        d="M273.39,76.17h3.13l-0.19,1.39c1.13-1.15,2.4-1.72,3.81-1.72c0.57,0,1.1,0.1,1.6,0.29
        c0.5,0.19,0.9,0.44,1.21,0.75s0.57,0.73,0.79,1.27c0.59-0.75,1.27-1.33,2.06-1.72c0.79-0.39,1.61-0.59,2.46-0.59
        c1.07,0,1.96,0.36,2.66,1.07c0.7,0.72,1.05,1.65,1.05,2.79c0,0.43-0.04,0.97-0.12,1.61l-1.05,7.61h-3.12l0.9-6.55
        c0.12-0.88,0.18-1.47,0.18-1.76c0-0.6-0.15-1.06-0.45-1.37c-0.3-0.31-0.73-0.47-1.28-0.47c-0.53,0-1.07,0.19-1.62,0.57
        s-0.96,0.88-1.25,1.51c-0.29,0.63-0.53,1.71-0.72,3.24l-0.66,4.83h-3.12l0.95-6.94c0.08-0.56,0.12-1.05,0.12-1.45
        c0-0.56-0.16-1-0.48-1.32c-0.32-0.33-0.75-0.49-1.27-0.49c-0.5,0-1.02,0.17-1.55,0.52c-0.53,0.35-0.94,0.82-1.24,1.42
        s-0.52,1.52-0.69,2.75l-0.72,5.5h-3.12L273.39,76.17z"
      />
    </g>
  </svg>
</template>
