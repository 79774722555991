import { computed, defineComponent, onBeforeUnmount, onMounted, PropType } from 'vue'
import AnapathTypeLogo from '@/app/shared/components/anapath-type-logo/anapath-type-logo.vue'
import LanguageSelector from '@/app/shared/components/selector/language/language-selector.vue'
import Login from '@/app/views/login/components/login/login.vue'
import LoginMenu from '@/app/views/login/components/menu/login-menu.vue'
import LoginChangePassword from '@/app/views/login/components/change-password/login-change-password.vue'
import LoginRecoverPassword from '@/app/views/login/components/recover-password/login-recover-password.vue'
import LoginRecoverPasswordEmailSent
    from '@/app/views/login/components/recover-password-email-sent/login-recover-password-email-sent.vue'
import { loginStoreModule } from '@/app/views/login/store/login-store-module'
import { LoginChangePasswordMode, LoginType } from '@/app/views/login/login-module.model'
import { store } from '@api/lib'
import { menuController } from '@ionic/vue'

export default defineComponent({
    name: 'LoginModule',
    components: {
        AnapathTypeLogo,
        LanguageSelector,
        Login,
        LoginMenu,
        LoginChangePassword,
        LoginRecoverPassword,
        LoginRecoverPasswordEmailSent
    },
    props: {
        loginType: {
            type: String as PropType<LoginType>,
            default: LoginType.LOGIN
        },
        loginChangePasswordMode: {
            type: String as PropType<LoginChangePasswordMode>,
            default: LoginChangePasswordMode.CHANGE_PASSWORD_AFTER_FIRST_LOGIN
        }
    },
    setup(props) {
        /// STORE MODULE OPERATIONS

        loginStoreModule.register()
        onBeforeUnmount(() => loginStoreModule.unregister())
        onMounted(() => store.dispatch('login/changeLoginType', props.loginType))

        /// COMPUTED

        const loginType =
            computed<LoginType>(() => store.getters['login/loginType'])
        const visibleLogin =
            computed(() => loginType.value === LoginType.LOGIN)
        const visibleRecoverPassword =
            computed(() => loginType.value === LoginType.RECOVER_PASSWORD)
        const visibleRecoverPasswordEmailSent =
            computed(() => loginType.value === LoginType.RECOVER_PASSWORD_EMAIL_SENT)
        const visibleChangePassword =
            computed(() => loginType.value === LoginType.NEW_PASSWORD)

        /// METHODS

        const openMenu =
            () => menuController.open('information-menu')

        return {
            /// COMPUTED
            visibleLogin,
            visibleRecoverPassword,
            visibleRecoverPasswordEmailSent,
            visibleChangePassword,
            /// METHODS
            openMenu
        }
    }
})

