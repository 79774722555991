import { computed, defineComponent } from 'vue'
import AnapathPremiumLogo from '@/app/assets/images/svg/anapath-premium-logo.vue'
import AnapathStandardLogo from '@/app/assets/images/svg/anapath-standard-logo.vue'
import AnapathTrackingLogo from '@/app/assets/images/svg/anapath-tracking-logo.vue'
import { store } from '@api/lib'
import { ProductType } from '@vitro/anapath-frontend-library-api'

export default defineComponent({
    name: 'AnapathTypeLogo',
    components: {
        AnapathPremiumLogo,
        AnapathStandardLogo,
        AnapathTrackingLogo
    },
    setup() {
        /// COMPUTED
        const visiblePremiumLogo =
            computed<boolean>(() => store.state.productType ===  ProductType.PREMIUM)
        const visibleStandardLogo =
            computed<boolean>(() => store.state.productType ===  ProductType.STANDARD)
        const visibleTrackingLogo =
            computed<boolean>(() => store.state.productType ===  ProductType.TRACKING)

        return {
            /// COMPUTED
            visiblePremiumLogo,
            visibleStandardLogo,
            visibleTrackingLogo
        }
    }
})
