import { computed, defineComponent, onMounted } from 'vue'
import { IonApp, IonRouterOutlet } from '@ionic/vue'
import { ProductType } from '@vitro/anapath-frontend-library-api'
import { store } from '@api/lib'

export default defineComponent({
    name: 'App',
    components: {
        IonApp,
        IonRouterOutlet
    },
    setup() {
        /// COMPUTED
        const theme =
            computed<string>(() => {
                switch (store.state.productType) {
                case ProductType.TRACKING:
                    return 'theme-tracking'
                case ProductType.STANDARD:
                    return 'theme-standard'
                case ProductType.PREMIUM:
                default:
                    return 'theme-premium'
                }
            })

        onMounted(() => {
            const body = document.body
            body.classList.add(theme.value)
        })

        return {
            /// COMPUTED
            theme
        }
    }
})
